import axios, { AxiosResponse } from "axios";
import { LanguageStore } from "../stores/useLanguage";
import { SERVERURL } from "../globals/constants";
import { Set } from "../models/Set";

const url = `${SERVERURL}/language`;

export const getLanguageList = async (
  set: Set<LanguageStore>,
  language: string
): Promise<number> => {
  try {
    const response: AxiosResponse = await axios.get<object>(
      `${url}/getallformatted/${language}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    set((state) => ({ ...state, dictionary: response.data }));
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};
