export const getImageGrid = (imageCount: number) => {
  let gridArr: string[] = [];
  switch (imageCount) {
    case 1:
      gridArr = ["1 / 1 / 13 / 13"];
      break;
    case 2:
      gridArr = ["1 / 1 / 13 / 7", "1 / 7 / 13 / 13"];
      break;
    case 3:
      gridArr = ["1 / 1 / 13 / 7", "1 / 7 / 7 / 13", "7 / 7 / 13 / 13"];
      break;
    case 4:
      gridArr = [
        "1 / 1 / 13 / 7",
        "1 / 7 / 7 / 13",
        "7 / 7 / 13 / 10",
        "7 / 10 / 13 / 13",
      ];
      break;
    case 5:
      gridArr = [
        "1 / 1 / 13 / 7",
        "1 / 7 / 7 / 10",
        "1 / 10 / 7 / 13",
        "7 / 7 / 13 / 10",
        "7 / 10 / 13 / 13",
      ];
      break;
    case 6:
      gridArr = [
        "1 / 1 / 13 / 6",
        "1 / 6 / 7 / 10",
        "7 / 6 / 13 / 10",
        "1 / 10 / 5 / 13",
        "5 / 10 / 9 / 13",
        "9 / 10 / 13 / 13",
      ];
      break;
  }

  return gridArr;
};
