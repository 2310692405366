import { useNavigate, useParams } from "react-router-dom";
import { StyledInfo } from "./Info.styled";
import { Background } from "../../components/background/Background";
import { Button, Typography } from "@mui/material";
import { useContext, useMemo, useState } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import { useTextFormatter } from "../../hooks/useTextFormatter";
import { ScrollToTop } from "../../components/ScrollToTop";

export const Info = () => {
  const { page } = useParams();
  const { dictionary } = useContext(LanguageContext);
  const { renderText } = useTextFormatter();
  const navigate = useNavigate();
  const [title, setTitle] = useState<string>("");
  const [body, setBody] = useState<string>("");

  useMemo(() => {
    switch (page) {
      case "privacy":
        setTitle(dictionary.info.privacy_title);
        setBody(dictionary.info.privacy_body);
        break;
      case "cookies":
        setTitle(dictionary.info.cookies_title);
        setBody(dictionary.info.cookies_body);
        break;
      case "conditions":
        setTitle(dictionary.info.conditions_title);
        setBody(dictionary.info.conditions_body);
        break;
    }
  }, [page, dictionary]);

  return (
    <StyledInfo>
      <ScrollToTop />
      <Background type="angled" />
      <Typography variant="h1">{title}</Typography>
      <Typography variant="h4">{renderText(body)}</Typography>
      <Button variant="outlined" onClick={() => navigate("/")}>
        {dictionary.buttons.backtohomebtn}
      </Button>
    </StyledInfo>
  );
};
