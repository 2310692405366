import axios, { AxiosResponse } from "axios";
import { SERVERURL } from "../globals/constants";
import { Service } from "../models/Service";

const url = `${SERVERURL}/language`;

export const getAllServices = async () => {
  const { data } = await axios.get<Service[]>(`${SERVERURL}/service/getall`, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });

  return data;
};

export const getService = async (id: number) => {
  const { data } = await axios.get<Service>(`${SERVERURL}/service/get/${id}`, {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  });

  return data;
};
