import { styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/Types";

export const StyledContactForm = styled("form")(
  ({ theme }: StyledComponentProps) => ({
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    width: "40rem",
    background: theme.color.background,
    boxShadow: `0px 0px 10px 10px ${theme.contrast.shadowColor}`,
    borderRadius: "25px",
    gap: ".5rem",
    ".row": {
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      p: {
        color: theme.palette.error.main,
        fontFamily: "'Product Sans', sans-serif",
        fontWeight: "bold",
        position: "absolute",
        padding: ".5rem",
        right: "0",
      },
      "input, textarea": {
        width: "30rem",
      },
    },
    ".message": {
      alignItems: "start",
      h4: {
        paddingTop: ".5rem",
      },
    },
    ".confirmation": {
      width: "30rem",
      alignSelf: "flex-end",
      position: "relative",
      justifyContent: "flex-start",
      ".Mui-error": {
        color: theme.palette.error.main,
        fontFamily: "'Product Sans', sans-serif",
        fontWeight: "bold",
        position: "absolute",
        left: "0",
        top: "2rem",
        padding: ".5rem 0",
      },
      ".MuiTypography-body1": {
        position: "relative",
        color: theme.color.dark,
      },
      ".Mui-checked": {
        color: theme.color.main,
      },
    },
    button: {
      width: "50%",
      alignSelf: "flex-end",
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
      width: "95%",
      boxSizing: "border-box",
      padding: "0",
      alignItems: "center",
      paddingBlock: "2rem",
      ".row": {
        flexDirection: "column",
        alignItems: "flex-start",
        width: "min-content",
        p: {
          color: theme.palette.error.main,
          fontFamily: "'Product Sans', sans-serif",
          fontWeight: "bold",
          position: "absolute",
          padding: ".5rem",
          right: "0",
        },
        "input[type='text'], textarea": {
          width: "17.5rem",
        },
      },
      ".confirmation": {
        width: "90%",
        flexDirection: "row",
        alignItems: "center",
        "input[type='checkbox']": {
          width: "3rem",
        },
        ".Mui-error": {
          left: "3.5rem",
          bottom: "0",
          top: "4.5rem",
          padding: "0",
          height: "min-content",
          width: "fit-content",
        },
      },
      button: {
        width: "80%",
        alignSelf: "center",
      },
    },
  })
);
