import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

interface StyledContentProps extends StyledComponentProps {
  buttonColor: string;
  dividerColor: string;
}

export const StyledContent = styled(Box)(
  ({ theme, buttonColor, dividerColor }: StyledContentProps) => ({
    width: "40rem",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    ".title": {
      width: "85%",
      color: "inherit",
    },
    ".divider": {
      width: "10rem",
      height: ".25rem",
      background: dividerColor ?? theme.color.main,
    },
    ".body": {
      display: "flex",
      flexDirection: "column",
      gap: "2rem",
      button: {
        textTransform: "inherit",
        color: buttonColor ? buttonColor : theme.color.background,
        fontSize: "1.2rem",
        "&:hover": {
          background: theme.color.main,
          color: buttonColor && theme.color.background,
        },
      },
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
      width: "90%",
      alignItems: "center",
      ".title": {
        textAlign: "center",
        width: "100%",
      },
      ".body": {
        alignItems: "center",
        textAlign: "center",
      },
    },
  })
);
