import { Box } from "@mui/material";
import { StyledAboutUs } from "./AboutUs.styled";
import { Content } from "../../components/content/Content";
import { useContext } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import image_electricianatwork from "../../images/AboutUs/electricianatwork.png";
import { ScrollToTop } from "../../components/ScrollToTop";
import { Background } from "../../components/background/Background";

export const AboutUs = () => {
  const { dictionary } = useContext(LanguageContext);

  return (
    <StyledAboutUs>
      <ScrollToTop />
      <Background type="aboutus" />
      <Box className="content" id="content">
        <Box className="imageWrapper">
          <img src={image_electricianatwork} />
        </Box>
        <Content
          title={dictionary.aboutus.title}
          body={dictionary.aboutus.body}
        />
      </Box>
    </StyledAboutUs>
  );
};
