import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

interface StyledNavbarProps extends StyledComponentProps {}

export const StyledNavbar = styled(Box)(({ theme }: StyledNavbarProps) => ({
  position: "relative",
  zIndex: "60",
  ".header": {
    boxShadow: `0px 0px 6px 0px ${theme.contrast.shadowColor}`,
    height: "7.5rem",
    width: "100%",
    background: theme.color.background,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    boxSizing: "border-box",
    ".menuIcon": {
      display: "none",
    },
    ".logoWrapper": {
      width: "10rem",
      img: {
        width: "100%",
      },
    },
    ".navlinks": {
      gap: "1rem",
      display: "flex",
      alignItems: "center",
    },
  },
  ".buttonWrapper": {
    display: "flex",
    gap: "1rem",
    svg: {
      cursor: "pointer",
      color: theme.color.dark,
      "&:hover": {
        color: theme.color.main,
        transition: ".5s",
      },
    },
  },
  ".languageSelectMenu": {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    boxShadow: `0px 0px 6px 0px ${theme.contrast.shadowColor}`,
    background: theme.color.background,
    borderRadius: "10px",
    padding: "1rem",
    boxSizing: "border-box",
    gap: ".5rem",
    top: "5.5rem",
    zIndex: "60",
    ".language": {
      width: "3rem",
      height: "2rem",
      boxShadow: `0px 0px 6px 0px ${theme.contrast.shadowColor}`,
      borderRadius: "5px",
      overflow: "hidden",
      opacity: "0.5",
    },
    ".selected": {
      opacity: "1",
    },
  },
  ".loginMenu": {
    display: "flex",
    alignItems: "center",
    position: "absolute",
    boxShadow: `0px 0px 6px 0px ${theme.contrast.shadowColor}`,
    background: theme.color.background,
    borderRadius: "10px",
    padding: "1rem",
    boxSizing: "border-box",
    gap: ".5rem",
    top: "5.5rem",
    right: "10rem",
    zIndex: "60",
    ".buttons": {
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      button: {
        width: "100%",
        textTransform: "inherit",
      },
      h3: {
        color: theme.color.dark,
        fontWeight: "bold",
        textAlign: "center",
      },
    },
  },
  ".avatar": {
    background: theme.color.main,
    borderRadius: "5px",
    cursor: "pointer",
    "&:hover": {
      background: theme.color.dark,
      transition: ".5s",
    },
  },
  ".navMenu": {
    background: theme.color.background,
    position: "absolute",
    paddingBlock: "1rem",
    zIndex: "-1",
    top: "7.5rem",
    width: "100%",
    boxShadow: `0px 0px 6px 0px ${theme.contrast.shadowColor}`,
    borderRadius: "0 0 50px 50px",
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
    ".header": {
      justifyContent: "space-between",
      paddingInline: "5rem",
      ".menuIcon": {
        display: "inherit",
      },
      ".navlinks": {
        display: "none",
      },
      ".buttonWrapper": {
        display: "none",
      },
    },
    ".navMenu": {
      ".buttonWrapper": {
        boxSizing: "border-box",
        justifyContent: "center",
        gap: "2rem",
        padding: "1rem",
        ".actionBtn": {
          display: "none",
        },
      },
      ".languageSelectMenu": {
        position: "relative",
        padding: ".5rem",
        inset: "auto",
      },
      ".navlinks": {
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        gap: "1rem",
        paddingBlock: "1rem",
      },
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
    ".header": {
      paddingInline: "2rem",
    },
    ".navMenu": {
      ".navlinks": {
        gap: ".5rem",
      },
    },
  },
}));
