import { Box, Button, Slide, Typography, useTheme } from "@mui/material";
import { StyledHome } from "./Home.styled";
import { HomeBackground } from "../../components/background/home/HomeBackground";
import { useContext, useMemo, useRef, useState } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import image_introduction from "../../images/Home/introductionImage.png";
import image_map from "../../images/Home/map.png";
import image_workarea from "../../images/Home/workareaimage.jpeg";
import { useTextFormatter } from "../../hooks/useTextFormatter";
import { Content } from "../../components/content/Content";
import { useNavigate } from "react-router-dom";
import { ScrollToTop } from "../../components/ScrollToTop";

export const Home = () => {
  const [isPageLoaded, setIsPageLoaded] = useState<boolean>(false);
  const { dictionary } = useContext(LanguageContext);
  const { renderText } = useTextFormatter();
  const navigate = useNavigate();
  const anchorPoint = useRef(null);
  const theme = useTheme();

  useMemo(() => {
    setIsPageLoaded(true);
  }, []);

  if (!dictionary) return <></>;

  return (
    <StyledHome>
      <ScrollToTop />
      <HomeBackground />
      <Box ref={anchorPoint} sx={{ overflow: "hidden" }}>
        <Slide
          in={isPageLoaded}
          {...{ timeout: 2000 }}
          container={anchorPoint.current}
          direction="up"
        >
          <Box className="homepage">
            <Typography variant="h1">{dictionary.home.title}</Typography>
            <Typography variant="h3">{dictionary.home.slogan}</Typography>
            <Button onClick={() => navigate("/contact")}>
              {dictionary.buttons.appointmentbtn}
            </Button>
          </Box>
        </Slide>
      </Box>
      <Box className="introductionWrapper">
        <Box className="imageWrapper">
          <img src={image_introduction} />
        </Box>
        <Content
          title={dictionary.home.introduction_title}
          body={dictionary.home.introduction_body}
          buttonText={dictionary.buttons.moreinfobtn}
          dividerColor={theme.color.lighter}
          onclick={() => navigate("/aboutus")}
        />
      </Box>
      <Box className="workareaWrapper">
        <Content
          title={dictionary.home.workarea_title}
          body={dictionary.home.workarea_body}
          dividerColor={theme.color.main}
        />
        <Box className="imageWrapper">
          <img className="map" src={image_map} alt="homeimage" />
          <img
            className="backgroundImage"
            src={image_workarea}
            alt="homeimage"
          />
        </Box>
      </Box>
      <Box className="ctaWrapper">
        <Box className="content">
          <Typography variant="h2">{dictionary.home.cta_title}</Typography>
          <Box className="divider" />
          <Box className="body">
            <Typography variant="h3">
              {renderText(dictionary.home.cta_body)}
            </Typography>
          </Box>
          <Button onClick={() => navigate("/contact")}>
            {dictionary.buttons.quotationbtncta}
          </Button>
        </Box>
      </Box>
    </StyledHome>
  );
};
