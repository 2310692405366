import { Box, Collapse, Typography } from "@mui/material";
import { Question } from "../../../models/Question";
import { StyledQuestion } from "./Question.styled";
import { useState } from "react";
import { CaretDown, CaretLeft, Lightbulb } from "@phosphor-icons/react";
import { useTextFormatter } from "../../../hooks/useTextFormatter";

type QuestionProps = {
  question: Question;
};

export const QuestionComponent = ({ question }: QuestionProps) => {
  const [isOpened, setIsOpened] = useState<boolean>(false);
  const { renderText } = useTextFormatter();

  return (
    <StyledQuestion>
      <Box className="header" onClick={() => setIsOpened(!isOpened)}>
        <Lightbulb size={50} />
        <Typography variant="h2">{question.question}</Typography>
        {isOpened ? (
          <CaretDown size={40} weight="bold" />
        ) : (
          <CaretLeft size={40} weight="bold" />
        )}
      </Box>
      <Collapse in={isOpened}>
        <Box className="body">
          <Typography variant="h4">{renderText(question.answer)}</Typography>
        </Box>
      </Collapse>
    </StyledQuestion>
  );
};
