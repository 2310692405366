import { Box, styled, Theme } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

interface StyledBackgroundProps extends StyledComponentProps {
  theme: Theme;
  primaryshapeheight: string;
  secondaryshapeheight: string;
}

export const StyledBackground = styled(Box)(
  ({
    theme,
    primaryshapeheight,
    secondaryshapeheight,
  }: StyledBackgroundProps) => ({
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: "-2",
    overflow: "hidden",
    top: "0",
    ".aboutus, .services, .contact, .angled": {
      background: `linear-gradient(90deg, ${theme.color.main} 0%, ${theme.color.main} 50%, ${theme.color.lighter} 100%);`,
      width: "120%",
    },
    ".aboutusprimary, .servicesprimary, .angledprimary": {
      height: primaryshapeheight,
      transform: "rotate(5deg) translate(-5rem, -4rem)",
    },
    ".aboutussecondary, .servicessecondary, .angledsecondary": {
      position: "absolute",
      bottom: "0",
      height: secondaryshapeheight,
      transform: "rotate(5deg) translate(-5rem, 6rem)",
    },
    ".achievementsprimary, .achievementprimary, .roundedprimary": {
      height: primaryshapeheight,
      background: theme.color.dark,
    },
    ".achievementssecondary, .roundedsecondary": {
      height: secondaryshapeheight,
      background: theme.color.dark,
      clipPath: "ellipse(71% 49% at 59% 81%)",
      width: "100%",
      transform: "rotate(180deg) translateY(0.1rem)",
    },
    ".contactprimary": {
      transform: "rotate(-10deg) translate(-1rem, 5rem)",
      height: "85%",
      bottom: "0",
      position: "absolute",
    },
    ".contactsecondary": {
      transform: "rotate(-10deg) translate(0rem, 5rem)",
      background: theme.color.background,
      position: "absolute",
      bottom: "0",
      height: "50%",
      zIndex: "3",
    },
    [`@media screen and (min-width: ${theme.breakpoints.values.mobile}px) and (max-width: ${theme.breakpoints.values.tablet}px)`]:
      {
        ".achievementsprimary, .achievementprimary, .roundedprimary": {
          height: "25rem",
        },
        ".aboutus, .services, .contact, .angled": {
          width: "150%",
        },
      },
    [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
      ".achievementsprimary, .achievementprimary": {
        height: "60rem",
      },
      ".achievementssecondary": {
        height: "15rem",
      },
      ".roundedsecondary": {
        height: "10rem",
      },
      ".aboutus, .services, .contact, .angled": {
        width: "150%",
      },
    },
  })
);
