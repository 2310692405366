import { createContext } from "react";
import nl_BE from "../lang/Language.nl-BE.json";
import en_EN from "../lang/Language.en-EN.json";

export const dictionaryList: any = { nl_BE, en_EN };

export const LanguageContext = createContext({
  language: "nl-BE",
  dictionary: dictionaryList,
});
