import { create } from "zustand";
import {
  getAchievement,
  getAchievements,
} from "../repositories/AchievementRepository";
import { Achievement } from "../models/Achievement";

export interface AchievementStore {
  fetchAchievements: () => Promise<Achievement[] | undefined>;
  fetchAchievement: (achievementId: number) => Promise<Achievement | undefined>;
}

export const useAchievement = create<AchievementStore>((set) => ({
  fetchAchievements: () => {
    return getAchievements();
  },
  fetchAchievement: (achievementId) => {
    return getAchievement(achievementId);
  },
}));
