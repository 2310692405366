import { useContext } from "react";
import { NavLink } from "../models/Types";
import { LanguageContext } from "../globals/LanguageContext";

export const useNavlinks = () => {
  const { dictionary } = useContext(LanguageContext);

  const navlinks: NavLink[] = [
    {
      title: dictionary.navlinks.home,
      path: "/",
      type: "link",
    },
    {
      title: dictionary.navlinks.aboutus,
      path: "/aboutus",
      type: "link",
    },
    {
      title: dictionary.navlinks.services,
      path: "/services",
      type: "link",
    },
    {
      title: dictionary.navlinks.achievements,
      path: "/achievements",
      type: "link",
    },
    {
      title: dictionary.navlinks.contact,
      path: "/contact",
      type: "button",
    },
  ];

  return { navlinks };
};
