import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledFooter = styled(Box)(({ theme }: StyledComponentProps) => ({
  ".body": {
    background: theme.color.dark,
    boxSizing: "border-box",
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    paddingBlock: "5rem",
    ".logoWrapper": {
      height: "100%",
      width: "15rem",
      img: {
        width: "100%",
        height: "100%",
        objectFit: "contain",
      },
    },
    ".content": {
      width: "max-content",
      display: "flex",
      flexDirection: "column",
      gap: ".5rem",
      height: "17.5rem",
      color: theme.color.background,
      ".divider": {
        background: theme.color.main,
        width: "5rem",
        height: ".25rem",
      },
      ".info": {
        display: "flex",
        gap: "1rem",
        alignItems: "center",
        svg: {
          color: theme.color.main,
        },
      },
      button: {
        background: "none",
        padding: "0",
        borderRadius: "0",
        minWidth: "0",
        "&:hover": {
          color: theme.color.main,
        },
      },
    },
  },
  ".copyrightbar": {
    height: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.color.background,
    p: {
      color: theme.contrast.lighter,
      display: "flex",
      alignItems: "center",
      fontSize: ".8rem",
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
    ".body": {
      flexDirection: "column",
      paddingBlock: "5rem",
      alignItems: "center",
      gap: "2rem",
      ".logoWrapper": {
        width: "75%",
        img: {
          width: "15rem",
        },
      },
      ".content": {
        width: "80%",
        ".info": {
          svg: {
            width: "2rem",
            height: "2rem",
          },
          h4: {
            fontWeight: "bold",
            width: "60%",
          },
        },
      },
    },
    ".copyrightbar": {
      textAlign: "center",
    },
  },
}));
