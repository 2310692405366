import { Box, Typography, useTheme } from "@mui/material";
import { Data } from "../../../models/Types";
import { StyledDataCard } from "./DataCard.styled";
import { data } from "../../../globals/constants";

type DataCardProps = {
  dataCard: Data;
};

export const DataCard = ({ dataCard }: DataCardProps) => {
  const theme = useTheme();

  return (
    <StyledDataCard theme={theme} isaddress={dataCard.value === data.address}>
      {dataCard.icon}
      <Box className="textWrapper">
        <Typography variant="h3">{dataCard.title}</Typography>
        <Typography variant="h4">{dataCard.value}</Typography>
      </Box>
    </StyledDataCard>
  );
};
