import axios, { AxiosResponse } from "axios";
import { SERVERURL } from "../globals/constants";
import { UserStore } from "../stores/useProfile";
import { LoginFormValues } from "../models/LoginForm";
import { Profile } from "../models/Profile";
import { Set } from "../models/Set";

export const getProfile = async (
  set: Set<UserStore>,
  loginData: LoginFormValues
) => {
  try {
    const response: AxiosResponse = await axios.post<Profile>(
      `${SERVERURL}/user/login`,
      loginData,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    set((state) => ({ ...state, user: response.data }));
    return response.status;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};
