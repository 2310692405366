import { Controller, useForm } from "react-hook-form";
import { StyledContactForm } from "./ContactForm.styled";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  TextField,
  Typography,
} from "@mui/material";
import { useContext } from "react";
import { LanguageContext } from "../../../globals/LanguageContext";
import { useTextFormatter } from "../../../hooks/useTextFormatter";
import { data, formPlaceholders } from "../../../globals/constants";
import { Message } from "../../../models/Message";

type ContactFormProps = {
  onSend: (message: Message) => void;
};

export const ContactForm = ({ onSend }: ContactFormProps) => {
  const { dictionary } = useContext(LanguageContext);
  const { renderText } = useTextFormatter();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Message>();

  const onSubmit = (data: Message) => {
    onSend(data);
    reset();
  };

  return (
    <StyledContactForm onSubmit={handleSubmit(onSubmit)}>
      <Box className="row">
        <Typography variant="h4">
          {renderText(dictionary.contact.forminputs_firstname)}
        </Typography>
        <TextField
          {...register("firstName", {
            required: dictionary.errormessages.form_fieldrequired,
          })}
          placeholder={formPlaceholders.firstName}
          error={!!errors.firstName}
          helperText={errors?.firstName?.message}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box className="row">
        <Typography variant="h4">
          {renderText(dictionary.contact.forminputs_lastname)}
        </Typography>
        <TextField
          {...register("lastName", {
            required: dictionary.errormessages.form_fieldrequired,
          })}
          placeholder={formPlaceholders.lastName}
          error={!!errors.lastName}
          helperText={errors?.lastName?.message}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box className="row">
        <Typography variant="h4">
          {renderText(dictionary.contact.forminputs_email)}
        </Typography>
        <TextField
          {...register("email", {
            required: dictionary.errormessages.form_fieldrequired,
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: dictionary.errormessages.form_wrongemail,
            },
          })}
          placeholder={formPlaceholders.email}
          error={!!errors.email}
          helperText={errors?.email?.message}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box className="row">
        <Typography variant="h4">
          {renderText(dictionary.contact.forminputs_phonenr)}
        </Typography>
        <TextField
          {...register("phone", {
            required: true,
            pattern: {
              value: /^(?:\+32|0)\d([\/\s]?\d{2}[\s.-]?){3}\d{2}$/i,
              message: dictionary.errormessages.form_wrongphonenr,
            },
          })}
          placeholder={data.phoneNr}
          error={!!errors.phone}
          helperText={errors?.phone?.message}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box className="row">
        <Typography variant="h4">
          {renderText(dictionary.contact.forminputs_address)}
        </Typography>
        <TextField
          {...register("address", {
            required: false,
          })}
          placeholder={data.address}
          error={!!errors.address}
          helperText={errors?.address?.message}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box className="row">
        <Typography variant="h4">
          {renderText(dictionary.contact.forminputs_subject)}
        </Typography>
        <TextField
          {...register("subject", {
            required: dictionary.errormessages.form_fieldrequired,
          })}
          placeholder={dictionary.placeholders.contactsubject}
          error={!!errors.subject}
          helperText={errors?.subject?.message}
          InputLabelProps={{ shrink: true }}
        />
      </Box>
      <Box className="row message">
        <Typography variant="h4">
          {renderText(dictionary.contact.forminputs_message)}
        </Typography>
        <TextField
          {...register("message", {
            required: dictionary.errormessages.form_fieldrequired,
          })}
          placeholder={dictionary.placeholders.contactmessage}
          error={!!errors.message}
          helperText={errors?.message?.message}
          InputLabelProps={{ shrink: true }}
          multiline
          InputProps={{
            rows: 5,
          }}
        />
      </Box>
      <Box className="row confirmation">
        <Controller
          name="confirmation"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FormControlLabel
              control={
                <Checkbox
                  {...field}
                  checked={Boolean(field.value)}
                  inputProps={{ "aria-label": "controlled-checkbox" }}
                />
              }
              label=""
            />
          )}
        />
        {errors.confirmation && (
          <FormHelperText error>
            {dictionary.errormessages.form_fieldrequired}
          </FormHelperText>
        )}
        <Typography variant="body1">
          {renderText(dictionary.contact.forminputs_confirmation)}
        </Typography>
      </Box>

      <Button type="submit">{dictionary.buttons.contactsendbtn}</Button>
    </StyledContactForm>
  );
};
