import { create } from "zustand";
import { getProfile } from "../repositories/UserRepository";
import { Profile } from "../models/Profile";
import { LoginFormValues } from "../models/LoginForm";

export interface UserStore {
  user?: Profile;
  fetchProfile: (loginData: LoginFormValues) => Promise<number | undefined>;
  logout: () => void;
}

export const useProfile = create<UserStore>((set) => ({
  fetchProfile: (loginData: LoginFormValues) => {
    return getProfile(set, loginData);
  },
  logout: () => {
    set((state) => ({ ...state, user: undefined }));
  },
}));
