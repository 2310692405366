import { Box, Button, Typography, useTheme } from "@mui/material";
import { StyledContent } from "./Content.styled";
import { useTextFormatter } from "../../hooks/useTextFormatter";

type ContentProps = {
  title: string;
  body: string;
  buttonText?: string;
  buttonColor?: string;
  dividerColor?: string;
  onclick?: () => void;
};

export const Content = ({
  title,
  body,
  buttonText,
  dividerColor,
  buttonColor,
  onclick,
}: ContentProps) => {
  const { renderText } = useTextFormatter();
  const theme = useTheme();

  return (
    <StyledContent
      theme={theme}
      buttonColor={buttonColor ?? ""}
      dividerColor={dividerColor ?? ""}
    >
      <Typography className="title">{renderText(title)}</Typography>
      <Box className="divider" />
      <Box className="body">
        <Typography>{renderText(body)}</Typography>
        {buttonText && (
          <Button variant="outlined" onClick={onclick}>
            {buttonText}
          </Button>
        )}
      </Box>
    </StyledContent>
  );
};
