import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/Types";

export const StyledAchievementCard = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    width: "20rem",
    height: "20rem",
    borderRadius: "25px",
    boxShadow: `0px 0px 3px 3px ${theme.contrast.shadowColor}`,
    overflow: "hidden",
    position: "relative",
    cursor: "pointer",
    ".imageWrapper": {
      height: "100%",
      width: "100%",
      img: {
        width: "100%",
        height: "100%",

        objectFit: "cover",
        zIndex: "-1",
      },
      ".darkoverlay": {
        background: theme.color.dark,
        position: "absolute",
        zIndex: "0",
        width: "100%",
        height: "100%",
        opacity: "0.5",
        transition: ".5s",
      },
    },
    h2: {
      zIndex: "2",
      position: "absolute",
      bottom: "2rem",
      fontSize: "2rem",
      left: "2rem",
      color: theme.color.background,
      textShadow: `3px 3px ${theme.contrast.shadowColor}`,
      transition: ".5s",
    },
    "&:hover": {
      ".imageWrapper .darkoverlay": {
        opacity: "0.2",
      },
      h2: {
        fontSize: "2.5rem",
      },
    },
  })
);
