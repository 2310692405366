import { useContext } from "react";
import { StyledLoginForm } from "./LoginForm.styled";
import { LanguageContext } from "../../../globals/LanguageContext";
import { useForm } from "react-hook-form";
import { LoginFormValues } from "../../../models/LoginForm";
import { Button, TextField, Typography } from "@mui/material";
import { useTextFormatter } from "../../../hooks/useTextFormatter";
import { formPlaceholders } from "../../../globals/constants";
import { useProfile } from "../../../stores/useProfile";

export const LoginForm = () => {
  const { dictionary } = useContext(LanguageContext);
  const { renderText } = useTextFormatter();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<LoginFormValues>();

  const { fetchProfile } = useProfile();

  const onSubmit = (data: LoginFormValues) => {
    fetchProfile(data);
  };

  return (
    <StyledLoginForm onSubmit={handleSubmit(onSubmit)}>
      <Typography variant="h4">
        {renderText(dictionary.login.forminputs_email)}
      </Typography>
      <TextField
        {...register("email", {
          required: dictionary.errormessages.form_fieldrequired,
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: dictionary.errormessages.form_wrongemail,
          },
        })}
        placeholder={formPlaceholders.email}
        error={!!errors.email}
        helperText={errors?.email?.message}
        InputLabelProps={{ shrink: true }}
        fullWidth
      />
      <Typography variant="h4">
        {renderText(dictionary.login.forminputs_password)}
      </Typography>
      <TextField
        type="password"
        {...register("password", {
          required: dictionary.errormessages.form_fieldrequired,
        })}
        placeholder={formPlaceholders.password}
        error={!!errors.password}
        helperText={errors?.password?.message}
        InputLabelProps={{ shrink: true }}
      />
      <Button type="submit" variant="outlined">
        {dictionary.buttons.loginbtn}
      </Button>
    </StyledLoginForm>
  );
};
