import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledAboutUs = styled(Box)(({ theme }: StyledComponentProps) => ({
  position: "relative",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ".content": {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "10rem",
    height: "60%",
    paddingTop: "12.5rem",
    paddingBottom: "10rem",
    ".imageWrapper": {
      boxShadow: `0px 0px 6px 0px ${theme.contrast.shadowColor}`,
      height: "25rem",
      width: "30rem",
      border: `4px solid ${theme.color.main}`,
      borderRadius: "10px",
      background: theme.color.dark,
      overflow: "hidden",
      img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
  },
  [`@media screen and (min-width: ${theme.breakpoints.values.mobile}px) and (max-width: ${theme.breakpoints.values.tablet}px)`]:
    {
      ".content": {
        flexDirection: "column",
        gap: "2rem",
        paddingTop: "5rem",
        ".imageWrapper": {
          height: "25rem",
          width: "30rem",
        },
      },
    },
  [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
    ".content": {
      flexDirection: "column",
      gap: "2rem",
      paddingTop: "5rem",
      ".imageWrapper": {
        height: "15rem",
        width: "20rem",
      },
    },
  },
}));
