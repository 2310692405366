import {
  Avatar,
  Box,
  Button,
  Grow,
  Slide,
  Typography,
  useTheme,
} from "@mui/material";
import { StyledNavbar } from "./Navbar.styled";
import logo from "../../images/Logo/selico.logo.svg";
import { GlobeHemisphereWest, List, User } from "@phosphor-icons/react";
import { useNavlinks } from "../../hooks/useNavLinks";
import { Navlink } from "./navlink/Navlink";
import { LanguageList } from "../../globals/constants";
import {
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import { LoginForm } from "../form/loginForm/LoginForm";
import { useNavigate } from "react-router-dom";
import { useProfile } from "../../stores/useProfile";
import { useTextFormatter } from "../../hooks/useTextFormatter";

type NavbarProps = {
  setLanguage: Dispatch<SetStateAction<string>>;
};

export const Navbar = ({ setLanguage }: NavbarProps) => {
  const { navlinks } = useNavlinks();
  const { dictionary, language } = useContext(LanguageContext);
  const theme = useTheme();
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");
  const [isLanguageMenuOpen, setIsLanguageMenuOpen] = useState<boolean>(false);
  const [isLoginMenuOpen, setIsLoginMenuOpen] = useState<boolean>(false);
  const [navMenuOpened, setNavMenuOpened] = useState<boolean>(false);
  const { renderText } = useTextFormatter();
  const { user, logout } = useProfile();

  useEffect(() => {
    setSelectedLanguage(language);
    setIsLoginMenuOpen(false);
  }, [language, user]);

  const onNavButtonClick = (path: string) => {
    navigate(path);
    setNavMenuOpened(false);
  };

  return (
    <StyledNavbar>
      <Box className="header">
        <Box className="logoWrapper">
          <img src={logo} alt="selico bv logo" />
        </Box>
        <Box className="navlinks">
          {navlinks.map((navlink, index) => (
            <Navlink
              navlink={navlink}
              key={index}
              onNavButtonClick={(path) => onNavButtonClick(path)}
            />
          ))}
        </Box>
        <Box className="buttonWrapper">
          <GlobeHemisphereWest
            size={40}
            weight="bold"
            onMouseOver={() => setIsLanguageMenuOpen(true)}
            onMouseLeave={() => setIsLanguageMenuOpen(false)}
          />
          <Grow in={isLanguageMenuOpen} style={{ transformOrigin: "0 0 0" }}>
            <Box
              className="languageSelectMenu"
              onMouseOver={() => setIsLanguageMenuOpen(true)}
              onMouseLeave={() => setIsLanguageMenuOpen(false)}
            >
              {LanguageList.map((lang, index) => (
                <Box
                  key={index}
                  className={`language ${
                    lang.code === selectedLanguage && "selected"
                  } `}
                  onClick={() => setLanguage(lang.code)}
                >
                  {lang.flag}
                </Box>
              ))}
            </Box>
          </Grow>
          {user ? (
            <Avatar
              alt="profile"
              variant="square"
              className="avatar"
              onClick={() => setIsLoginMenuOpen(!isLoginMenuOpen)}
            >
              W
            </Avatar>
          ) : (
            <User
              size={40}
              weight="bold"
              onClick={() => setIsLoginMenuOpen(!isLoginMenuOpen)}
              color={isLoginMenuOpen ? theme.color.main : theme.color.dark}
            />
          )}
          <Grow in={isLoginMenuOpen} style={{ transformOrigin: "0 1 0" }}>
            <Box className="loginMenu">
              {user ? (
                <Box className="buttons">
                  <Typography>
                    {renderText(dictionary.login.loginmenu_title)}
                  </Typography>
                  <Button onClick={() => navigate("/admin/adminpanel")}>
                    {dictionary.buttons.adminpanelbtn}
                  </Button>
                  <Button variant="outlined" onClick={logout}>
                    {dictionary.buttons.logoutbtn}
                  </Button>
                </Box>
              ) : (
                <LoginForm />
              )}
            </Box>
          </Grow>
        </Box>
        <List
          size={48}
          weight="bold"
          className="menuIcon"
          onClick={() => {
            setNavMenuOpened(!navMenuOpened);
            setIsLanguageMenuOpen(true);
          }}
        />
      </Box>

      <Slide in={navMenuOpened}>
        <Box className="navMenu">
          <Box className="navlinks">
            {navlinks.map((navlink, index) => (
              <Navlink
                navlink={navlink}
                key={index}
                onNavButtonClick={(path) => onNavButtonClick(path)}
              />
            ))}
          </Box>
          <Box className="buttonWrapper">
            <GlobeHemisphereWest
              size={40}
              weight="bold"
              className="actionBtn"
              onMouseOver={() => setIsLanguageMenuOpen(true)}
              onMouseLeave={() => setIsLanguageMenuOpen(false)}
            />
            <Grow in={isLanguageMenuOpen} style={{ transformOrigin: "0 0 0" }}>
              <Box
                className="languageSelectMenu"
                onMouseOver={() => setIsLanguageMenuOpen(true)}
                onMouseLeave={() => setIsLanguageMenuOpen(false)}
              >
                {LanguageList.map((lang, index) => (
                  <Box
                    key={index}
                    className={`language ${
                      lang.code === selectedLanguage && "selected"
                    } `}
                    onClick={() => setLanguage(lang.code)}
                  >
                    {lang.flag}
                  </Box>
                ))}
              </Box>
            </Grow>
            {user ? (
              <Avatar
                alt="profile"
                variant="square"
                className="avatar"
                onClick={() => setIsLoginMenuOpen(!isLoginMenuOpen)}
              >
                W
              </Avatar>
            ) : (
              <User
                className="actionBtn"
                size={40}
                weight="bold"
                onClick={() => setIsLoginMenuOpen(!isLoginMenuOpen)}
                color={isLoginMenuOpen ? theme.color.main : theme.color.dark}
              />
            )}
            <Grow in={isLoginMenuOpen} style={{ transformOrigin: "0 1 0" }}>
              <Box className="loginMenu">
                {user ? (
                  <Box className="buttons">
                    <Typography variant="h3">Profile</Typography>
                    <Button>{dictionary.buttons.adminpanelbtn}</Button>
                    <Button variant="outlined">
                      {dictionary.buttons.logoutbtn}
                    </Button>
                  </Box>
                ) : (
                  <LoginForm />
                )}
              </Box>
            </Grow>
          </Box>
        </Box>
      </Slide>
    </StyledNavbar>
  );
};
