import { Box, Button, Collapse, Typography } from "@mui/material";
import { Background } from "../../components/background/Background";
import { StyledFAQ } from "./FAQ.styled";
import { useContext } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import { questions } from "../../globals/constants";
import { QuestionComponent } from "./question/Question";
import { useNavigate } from "react-router-dom";
import { ScrollToTop } from "../../components/ScrollToTop";

export const FAQ = () => {
  const { dictionary } = useContext(LanguageContext);
  const navigate = useNavigate();

  return (
    <StyledFAQ>
      <ScrollToTop />
      <Background
        type="rounded"
        primaryshapeheight="30rem"
        secondaryshapeheight="20rem"
      />
      <Typography variant="h1">{dictionary.faq.title}</Typography>
      <Box className="questionWrapper">
        {questions.length === 0 && (
          <Typography variant="h3">{dictionary.faq.nocontent}</Typography>
        )}
        {questions.map((question, index) => (
          <QuestionComponent question={question} key={index} />
        ))}
      </Box>
      <Button variant="outlined" onClick={() => navigate("/contact")}>
        {dictionary.faq.contactbtn}
      </Button>
    </StyledFAQ>
  );
};
