import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/Types";

export const StyledService = styled(Box)(({ theme }: StyledComponentProps) => ({
  minHeight: "50rem",
  position: "relative",
  h1: {
    color: theme.color.background,
    textAlign: "center",
    paddingBlock: "3.5rem",
  },
  ".contentWrapper": {
    paddingBottom: "5rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    ".serviceContent": {
      display: "flex",
      background: theme.color.background,
      borderRadius: "25px",
      padding: "2rem",
      alignItems: "center",
      justifyContent: "center",
      boxShadow: `0px 0px 3px 3px ${theme.contrast.shadowColor}`,
      gap: "5rem",
      width: "min-content",
      ".imageWrapper": {
        height: "25rem",
        width: "30rem",
        border: `4px solid ${theme.color.main}`,
        borderRadius: "10px",
        overflow: "hidden",
        img: {
          width: "100%",
          height: "100%",
          objectFit: "cover",
        },
      },
    },
    svg: {
      color: theme.color.main,
    },
    ".serviceContent:last-of-type .imageWrapper": {
      border: `4px solid ${theme.color.dark}`,
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
    h1: {
      fontSize: "48px",
    },
    ".contentWrapper": {
      ".serviceContent": {
        flexDirection: "column",
        gap: "1rem",
        width: "95%",
        padding: "2rem .5rem",
        boxSizing: "border-box",
        ".imageWrapper": {
          height: "15rem",
          width: "20rem",
        },
      },
    },
  },
}));
