import { Box, Typography, useTheme } from "@mui/material";
import { Background } from "../../../components/background/Background";
import { Service } from "../../../models/Service";
import { StyledService } from "./Service.styled";
import { useService } from "../../../stores/useServices";
import { useNavigate, useParams } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../../globals/LanguageContext";
import { Content } from "../../../components/content/Content";
import { CaretDown } from "@phosphor-icons/react";
import { ScrollToTop } from "../../../components/ScrollToTop";

export const ServicePage = () => {
  const { fetchService } = useService();
  const { id } = useParams();
  const navigate = useNavigate();
  const [service, setService] = useState<Service>();
  const { language, dictionary } = useContext(LanguageContext);
  const theme = useTheme();

  useEffect(() => {
    fetchService(Number(id)).then((res) => {
      setService(res);
    });
  }, [id]);

  return (
    <StyledService>
      <ScrollToTop />
      <Background type="rounded" primaryshapeheight="10rem" />
      {service?.content.length && service?.content.length > 1 && (
        <Background
          type="angled"
          primaryshapeheight="0"
          secondaryshapeheight="40rem"
        />
      )}
      <Typography variant="h1">
        {language === "nl_BE"
          ? service?.title.translationDutch
          : service?.title.translationEnglish}
      </Typography>
      <Box className="contentWrapper">
        {service?.content.map((content, index) => (
          <>
            <Box className="serviceContent">
              {index % 2 === 0 && (
                <Box className="imageWrapper">
                  <img src={`data:image/png;base64,${content.image}`} />
                </Box>
              )}
              <Content
                title={
                  language === "nl_BE"
                    ? content?.title.translationDutch
                    : content?.title.translationEnglish
                }
                body={
                  language === "nl_BE"
                    ? content?.body.translationDutch
                    : content?.body.translationEnglish
                }
                buttonText={dictionary.buttons.contactbtn}
                buttonColor={theme.color.main}
                onclick={() => {
                  navigate("/contact");
                }}
              />
              {index % 2 != 0 && (
                <Box className="imageWrapper">
                  <img src={`data:image/png;base64,${content.image}`} />
                </Box>
              )}
            </Box>
            {index < service.content.length - 1 && (
              <CaretDown size={64} weight="bold" />
            )}
          </>
        ))}
      </Box>
    </StyledService>
  );
};
