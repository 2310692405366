import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/Types";

export const StyledServiceCard = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    width: "30rem",
    borderRadius: "25px",
    boxShadow: `0px 0px 3px 3px ${theme.contrast.shadowColor}`,
    display: "flex",
    overflow: "hidden",
    flexDirection: "column",
    alignItems: "center",
    gap: "1rem",
    zIndex: "57",
    background: theme.color.background,
    paddingBottom: "1rem",
    ".header": {
      position: "relative",
      maxWidth: "100%",
      ".imageOverlay": {
        background: theme.color.dark,
        opacity: "0.4",
        position: "absolute",
        top: "0",
        width: "100%",
        height: "15rem",
      },
      img: {
        width: "30rem",
        height: "15rem",
        objectFit: "cover",
      },
      h3: {
        position: "absolute",
        bottom: "2rem",
        left: "2rem",
        fontWeight: "bold",
        paddingRight: "1rem",
        color: theme.color.background,
        fontSize: "2.5rem",
        boxSizing: "border-box",
      },
    },
    ".body": {
      width: "100%",
      boxSizing: "border-box",
      padding: "0 2rem",
      height: "11rem",
      p: {
        textOverflow: "ellipsis",
        overflow: "hidden",
        width: "100%",
        height: "100%",
        color: theme.color.dark,
      },
    },
    button: {
      width: "60%",
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
      width: "20rem",
      ".header h3": {
        margin: "auto",
        left: "0",
        width: "100%",
        textAlign: "center",
        paddingInline: "1rem",
      },
      ".body": {
        height: "fit-content",
        textAlign: "center",
      },
    },
  })
);
