import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

interface StyledImageGridItemProps extends StyledComponentProps {
  gridArea: string;
}

export const StyledImageGrid = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    display: "flex",
    flexDirection: "column",
    gap: ".5rem",
    paddingBlock: "3rem",
    zIndex: "100",
    ".overlay": {
      position: "fixed",
      width: "100%",
      height: "100%",
      background: "rgba(28, 28, 28, 0.7)",
      top: "0",
      zIndex: "50",
      display: "flex",
      justifyContent: "center",
      paddingBlock: "10rem",
      ".closeBtn": {
        position: "absolute",
        right: "10rem",
        top: "10rem",
        color: theme.color.lighter,
        cursor: "pointer",
        "&:hover": {
          color: theme.color.light,
          transition: ".5s",
        },
      },
      ".contentWrapper": {
        overflow: "hidden",
        height: "40rem",
        display: "flex",
        gap: "4rem",
        alignItems: "center",
        img: {
          width: "60rem",
          height: "100%",
          borderRadius: "25px",
          userSelect: "none",
        },
        svg: {
          color: theme.color.background,
          cursor: "pointer",
          "&:hover": {
            color: theme.color.lighter,
            transition: ".5s",
          },
        },
      },
    },
    ".grid": {
      display: "grid",
      gridTemplateColumns: "repeat(12, 1fr)",
      gridTemplateRows: "repeat(12, 1fr)",
      width: "50%",
      height: "30rem",
      margin: "auto",
      gap: ".5rem",
      borderRadius: "25px",
      overflow: "hidden",
      boxShadow: `0px 0px 3px 3px ${theme.contrast.shadowColor}`,
      border: `4px solid ${theme.color.main}`,
      background: theme.color.background,
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
      ".grid": {
        width: "90%",
        display: "flex",
        flexDirection: "column",
        height: "auto",
      },
    },
  })
);

export const StyledImageGridItem = styled(Box)(
  ({ theme, gridArea }: StyledImageGridItemProps) => ({
    gridArea: gridArea,
    position: "relative",
    zIndex: "2",
    button: {
      position: "absolute",
      display: "flex",
      gap: ".5rem",
      right: ".5rem",
      bottom: ".5rem",
      background: theme.color.main,
      color: theme.color.background,
    },
    img: {
      width: "100%",
      height: "100%",
      objectFit: "cover",
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
      button: {
        display: "none",
      },
    },
  })
);
