import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledAchievements = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    minHeight: "60rem",
    display: "flex",
    flexDirection: "column",
    gap: "2rem",
    ".header": {
      display: "flex",
      flexDirection: "column",
      gap: "5rem",
      padding: "3.5rem",
      h1: {
        color: theme.color.background,
        textAlign: "center",
      },
      ".content": {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: "10rem",
        ".imageWrapper": {
          boxShadow: `0px 0px 6px 0px ${theme.contrast.shadowColor}`,
          height: "25rem",
          width: "30rem",
          border: `4px solid ${theme.color.main}`,
          borderRadius: "1rem",
          position: "relative",
          overflow: "hidden",
          img: {
            width: "100%",
            height: "100%",
            objectFit: "cover",
          },
        },
      },
    },
    ".achievementWrapper": {
      padding: "5rem 10rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      flexWrap: "wrap",
      gap: "1rem",
      h3: {
        color: theme.color.main,
        fontWeight: "bold",
      },
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
      ".header": {
        padding: "1rem",
        gap: "1rem",
        ".content": {
          flexDirection: "column",
          gap: "1rem",
          ".imageWrapper": {
            width: "20rem",
            height: "15rem",
          },
        },
      },
      ".achievementWrapper": {
        padding: "2rem",
        h3: {
          textAlign: "center",
        },
      },
    },
  })
);
