import { useCallback } from "react";

export const useTextFormatter = () => {
  const renderText = useCallback((text: string) => {
    if (!text) return null;

    // Apply replacements for custom tags
    text = text.replace(/\[bold\](.*?)\[\/bold\]/g, "<b>$1</b>");

    text = text.replace(
      /\[h1\](.*?)\[\/h1\]/g,
      `<span style="font-size: 4rem; font-weight: 600; line-height: 90px; letter-spacing: 1px;">$1</span>`
    );

    text = text.replace(
      /\[h2\](.*?)\[\/h2\]/g,
      `<span style="font-size: 32px; font-weight: 600; line-height: 40px;">$1</span>`
    );

    text = text.replace(
      /\[h3\](.*?)\[\/h3\]/g,
      `<span style="font-size: 24px; font-weight: 400; line-height: 45px; letter-spacing: 1px;">$1</span>`
    );

    text = text.replace(
      /\[h4\](.*?)\[\/h4\]/g,
      `<span style="font-size: 18px; font-weight: 400; line-height: 27px;">$1</span>`
    );

    text = text.replace(
      /\[h5\](.*?)\[\/h5\]/g,
      `<span style="font-size: 18px; font-weight: 600; line-height: 24px;">$1</span>`
    );

    text = text.replace(
      /\[color:(["']?)(.*?)\1\](.*?)\[\/color\]/g,
      (match, p1, color, content) => {
        return `<span style="color:${color}">${content}</span>`;
      }
    );

    text = text.replace(/\[\/breakline\]/g, "<br />");

    text = text.replace(
      /\[link:(["']?)(.*?)\1\](.*?)\[\/link\]/g,
      '<a href="$2" style="text-decoration: none">$3</a>'
    );

    // Wrap the default text in h5 style
    text = `<span style="font-size: 16px; font-weight: 500; line-height: 24px; letter-spacing: .5px">${text}</span>`;

    return <span dangerouslySetInnerHTML={{ __html: text }} />;
  }, []);

  return { renderText };
};
