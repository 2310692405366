import { Box, Button, Typography } from "@mui/material";
import { StyledServices } from "./Services.styled";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import { ServiceCard } from "../../components/cards/serviceCard/ServiceCard";
import { useNavigate } from "react-router-dom";
import { Background } from "../../components/background/Background";
import { useTextFormatter } from "../../hooks/useTextFormatter";
import { useService } from "../../stores/useServices";
import { Service } from "../../models/Service";

export const Services = () => {
  const navigate = useNavigate();
  const { dictionary } = useContext(LanguageContext);
  const { renderText } = useTextFormatter();
  const { fetchServices } = useService();
  const [services, setServices] = useState<Service[] | undefined>();

  useEffect(() => {
    fetchServices().then((res) => {
      setServices(res);
    });
  }, []);

  return (
    <StyledServices>
      <Background type="services" primaryshapeheight="25rem" />
      <Typography variant="h1">{dictionary.services.title}</Typography>
      <Box className="serviceWrapper">
        <Box className="content">
          {services &&
            services.map(
              (service, index) =>
                service.enabled && <ServiceCard service={service} key={index} />
            )}
        </Box>
      </Box>
      <Box className="infoWrapper">
        <Box className="content">
          <Typography variant="h2">{dictionary.services.info_title}</Typography>
          <Typography variant="h5">
            {renderText(dictionary.services.info_text)}
          </Typography>
          <Button variant="outlined" onClick={() => navigate("/contact")}>
            {dictionary.buttons.contactbtn}
          </Button>
        </Box>
      </Box>
    </StyledServices>
  );
};
