import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledInfo = styled(Box)(({ theme }: StyledComponentProps) => ({
  position: "relative",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  paddingTop: "2rem",
  paddingBottom: "7.5rem",
  h1: {
    paddingBottom: "4rem",
    color: theme.color.background,
    textAlign: "center",
  },
  h4: {
    minHeight: "20rem",
    width: "65rem",
    paddingBlock: "2rem",
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
    h1: {
      fontSize: "2.5rem",
      padding: "2rem",
      lineHeight: "50px",
    },
    h4: {
      width: "90%",
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
    paddingTop: "0",
    h1: {
      height: "5rem",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
  },
}));
