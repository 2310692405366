import { useContext, useEffect, useState } from "react";
import { NavLink } from "../../../models/Types";
import { StyledNavlink } from "./Navlink.styled";
import { Box, Button, Grow, useTheme } from "@mui/material";
import {
  CaretDown,
  CaretRight,
  ChargingStation,
  Leaf,
  Lightbulb,
  Lightning,
  WifiHigh,
  Wrench,
} from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";
import { useService } from "../../../stores/useServices";
import { Service } from "../../../models/Service";
import { LanguageContext } from "../../../globals/LanguageContext";
import { cp } from "fs";

type NavlinkProps = {
  navlink: NavLink;
  onNavButtonClick: (path: string) => void;
};

const serviceIcons = [
  <Lightning size={24} />,
  <Lightbulb size={24} />,
  <WifiHigh size={24} />,
  <ChargingStation size={24} />,
  <Leaf size={24} />,
  <Wrench size={24} />,
];

export const Navlink = ({ navlink, onNavButtonClick }: NavlinkProps) => {
  const navigate = useNavigate();
  const [isServicesOpen, setIsServicesOpen] = useState<boolean>(false);
  const { language } = useContext(LanguageContext);

  const { fetchServices } = useService();
  const [services, setServices] = useState<Service[] | undefined>();

  useEffect(() => {
    fetchServices().then((res) => {
      setServices(res);
    });
  }, []);

  const onClick = (path: string) => {
    setIsServicesOpen(false);
    onNavButtonClick(path);
  };

  return (
    <StyledNavlink>
      <Button
        className={`navlink ${navlink.type === "button" && "navbutton"} ${
          navlink.path === "/services" && isServicesOpen && "selected"
        }`}
        onMouseOver={() =>
          navlink.path === "/services" && setIsServicesOpen(true)
        }
        onMouseOut={() =>
          navlink.path === "/services" && setIsServicesOpen(false)
        }
        onClick={() => onClick(navlink.path)}
      >
        {navlink.title}
        {navlink.path === "/services" ? (
          isServicesOpen ? (
            <CaretDown size={20} weight="bold" />
          ) : (
            <CaretRight size={20} weight="bold" />
          )
        ) : (
          <></>
        )}
      </Button>
      {navlink.path === "/services" && (
        <Grow in={isServicesOpen} style={{ transformOrigin: "0 0 0" }}>
          <Box
            className="sublinks"
            onMouseEnter={() =>
              navlink.path === "/services" && setIsServicesOpen(!isServicesOpen)
            }
            onMouseLeave={() =>
              navlink.path === "/services" && setIsServicesOpen(!isServicesOpen)
            }
          >
            {services &&
              services.map(
                (service, index) =>
                  service.enabled && (
                    <Box className="sublink" key={index}>
                      {serviceIcons[index]}
                      <Button
                        className="navlink"
                        onClick={() => onClick(`/services/${service.id}`)}
                      >
                        {language === "nl_BE"
                          ? service.title.translationDutch
                          : service.title.translationEnglish}
                      </Button>
                    </Box>
                  )
              )}
          </Box>
        </Grow>
      )}
      {navlink.path === "/services" && (
        <Box className="mobileSubLinks">
          {services &&
            services.map(
              (service, index) =>
                service.enabled && (
                  <Box
                    className="sublink"
                    key={index}
                    onClick={() => onClick(`/services/${service.id}`)}
                  >
                    {serviceIcons[index]}
                    <Button className="navlink">
                      {language === "nl_BE"
                        ? service.title.translationDutch
                        : service.title.translationEnglish}
                    </Button>
                  </Box>
                )
            )}
        </Box>
      )}
    </StyledNavlink>
  );
};
