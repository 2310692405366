import { Box, Button, Fade, useTheme } from "@mui/material";
import { StyledImageGrid, StyledImageGridItem } from "./ImageGrid.styled";
import { useContext, useEffect, useState } from "react";
import { getImageGrid } from "../../globals/utils";
import { CaretLeft, CaretRight, Resize, X } from "@phosphor-icons/react";
import { LanguageContext } from "../../globals/LanguageContext";

type ImageGridProps = {
  imageList: string[];
};

export const ImageGrid = ({ imageList }: ImageGridProps) => {
  const theme = useTheme();
  const [imageGrid, setImageGrid] = useState<string[]>([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState<number>(0);
  const [isOverlayOpened, setIsOverlayOpened] = useState<boolean>(false);
  const { dictionary } = useContext(LanguageContext);

  useEffect(() => {
    setImageGrid(getImageGrid(imageList.length > 6 ? 6 : imageList.length));
  }, [imageList]);

  const CycleImages = (direction: number) => {
    const newImageIndex = selectedImageIndex + direction;

    if (newImageIndex < 0) setSelectedImageIndex(imageList.length - 1);
    else if (newImageIndex >= imageList.length) setSelectedImageIndex(0);
    else setSelectedImageIndex(newImageIndex);
  };

  return (
    <StyledImageGrid>
      <Fade in={isOverlayOpened}>
        <Box className="overlay">
          <X
            className="closeBtn"
            size={48}
            weight="bold"
            onClick={() => setIsOverlayOpened(false)}
          />
          <Box className="contentWrapper">
            {imageList.length > 1 && (
              <CaretLeft
                size={45}
                weight="bold"
                onClick={() => CycleImages(-1)}
              />
            )}

            <img
              src={`data:image/png;base64,${imageList[selectedImageIndex]}`}
            />
            {imageList.length > 1 && (
              <CaretRight
                size={45}
                weight="bold"
                onClick={() => CycleImages(1)}
              />
            )}
          </Box>
        </Box>
      </Fade>

      <Box className="grid">
        {imageList.slice(0, 6).map((image, index, array) => (
          <StyledImageGridItem
            theme={theme}
            gridArea={imageGrid[index]}
            key={index}
          >
            <img src={`data:image/png;base64,${image}`} />
            {index === array.length - 1 && (
              <Button onClick={() => setIsOverlayOpened(true)}>
                <Resize size={24} /> {dictionary.buttons.expandbtn}
              </Button>
            )}
          </StyledImageGridItem>
        ))}
      </Box>
    </StyledImageGrid>
  );
};
