import { useNavigate, useParams } from "react-router-dom";
import { ImageGrid } from "../../../components/imageGrid/ImageGrid";
import { StyledAchievement } from "./Achievement.styled";
import { useContext, useEffect, useState } from "react";
import { Achievement } from "../../../models/Achievement";
import { Button, Typography } from "@mui/material";
import { ArrowLeft } from "@phosphor-icons/react";
import { LanguageContext } from "../../../globals/LanguageContext";
import { Background } from "../../../components/background/Background";
import { useAchievement } from "../../../stores/useAchievement";

export const AchievementPage = () => {
  const { id } = useParams();
  const [achievement, setAchievement] = useState<Achievement>();
  const { dictionary, language } = useContext(LanguageContext);
  const navigate = useNavigate();

  const { fetchAchievement } = useAchievement();

  useEffect(() => {
    fetchAchievement(Number(id)).then((res) => {
      if (res) setAchievement(res);
    });
  }, []);

  return (
    <StyledAchievement>
      <Background
        type="achievements"
        primaryshapeheight="0rem"
        secondaryshapeheight="55rem"
      />
      <Button className="backbtn" onClick={() => navigate("/achievements")}>
        <ArrowLeft size={24} /> {dictionary.buttons.backbtn}
      </Button>
      <Typography variant="h1">{achievement?.location}</Typography>
      <Typography variant="h2">
        {language === "nl_BE"
          ? achievement?.description.translationDutch
          : achievement?.description.translationEnglish}
      </Typography>
      {achievement && <ImageGrid imageList={achievement.images}></ImageGrid>}
    </StyledAchievement>
  );
};
