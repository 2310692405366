import { Box, Typography } from "@mui/material";
import { StyledContact } from "./Contact.styled";
import { useContext, useMemo, useState } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import { useTextFormatter } from "../../hooks/useTextFormatter";
import { ContactForm } from "../../components/form/contactForm/ContactForm";
import { DataCard } from "../../components/cards/dataCard/DataCard";
import { ScrollToTop } from "../../components/ScrollToTop";
import { useDataCards } from "../../hooks/useDataCards";
import { Background } from "../../components/background/Background";
import { MessagePoppup } from "../../components/messagePoppup/MessagePoppup";
import { Message } from "../../models/Message";
import { useMessage } from "../../stores/useMessage";

export const Contact = () => {
  const { dictionary } = useContext(LanguageContext);
  const { renderText } = useTextFormatter();
  const { dataCards } = useDataCards(40);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [statusCode, setStatusCode] = useState<number>(0);
  const [triggerUpdate, setTriggerUpdate] = useState<boolean>(false);
  const { addMessage } = useMessage();

  const sendMessage = async (message: Message) => {
    const newMessage: Message = {
      ...message,
      message: message.message.replaceAll("\n", "[/breakline]"),
    };

    const status = await addMessage(newMessage);
    setStatusCode(status);
    setShowMessage(true);
  };

  useMemo(() => {
    setTriggerUpdate(true);
    setTimeout(() => {
      setTriggerUpdate(false);
    }, 1000);
  }, [showMessage]);

  return (
    <StyledContact>
      {triggerUpdate && <ScrollToTop />}
      <Background type="contact" />
      {showMessage && (
        <MessagePoppup
          text={[
            dictionary.messagePoppups.message_success,
            dictionary.messagePoppups.message_error,
          ]}
          statusCode={statusCode}
          closeMessage={() => setShowMessage(false)}
        />
      )}

      <Box className="header">
        <Typography variant="h1">{dictionary.contact.title}</Typography>
        <Typography className="description">
          {renderText(dictionary.contact.description)}
        </Typography>
        <Typography className="warning">
          {renderText(dictionary.contact.warning)}
        </Typography>
      </Box>
      <Box className="body">
        <ContactForm onSend={(message) => sendMessage(message)} />
        <Box className="infoWrapper">
          <Typography variant="h2">{dictionary.data.title}</Typography>
          <Box className="divider" />
          <Box className="content">
            {dataCards.map((dataCard, index) => (
              <DataCard dataCard={dataCard} key={index} />
            ))}
          </Box>
        </Box>
      </Box>
      <Box className="mapWrapper">
        <Box className="map">
          <iframe src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=Halensebaan%20155A%203390%20Tielt-Winge+(Selico%20bv)&amp;t=&amp;z=13&amp;ie=UTF8&amp;iwloc=B&amp;output=embed" />
        </Box>
      </Box>
    </StyledContact>
  );
};
