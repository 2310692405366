import { Box, Typography, useTheme } from "@mui/material";
import { StyledAchievements } from "./Achievements.styled";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import { Content } from "../../components/content/Content";
import image_introduction from "../../images/Achievements/lamps.png";
import { AchievementCard } from "../../components/cards/achievementCard/AchievementCard";
import { useNavigate } from "react-router-dom";
import { Background } from "../../components/background/Background";
import { Achievement } from "../../models/Achievement";
import { useAchievement } from "../../stores/useAchievement";

export const Achievements = () => {
  const { dictionary } = useContext(LanguageContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const [achievements, setAchievements] = useState<Achievement[]>([]);
  const { fetchAchievements } = useAchievement();

  useEffect(() => {
    fetchAchievements().then((res) => {
      if (res) setAchievements(res);
    });
  }, []);

  return (
    <StyledAchievements>
      <Background
        type="achievements"
        primaryshapeheight="20rem"
        secondaryshapeheight="50rem"
      />
      <Box className="header">
        <Typography variant="h1">{dictionary.achievements.title}</Typography>
        <Box className="content">
          <Box className="imageWrapper">
            <img src={image_introduction} />
          </Box>
          <Content
            title={dictionary.achievements.introduction_title}
            body={dictionary.achievements.introduction_body}
            buttonText={dictionary.buttons.quotationbtn}
            onclick={() => navigate("/contact")}
            dividerColor={theme.color.main}
          />
        </Box>
      </Box>
      <Box className="achievementWrapper">
        {achievements.length > 0 ? (
          achievements.map((achievement, index) => (
            <AchievementCard achievement={achievement} key={index} />
          ))
        ) : (
          <Typography variant="h3">
            {dictionary.errormessages.noachievements}
          </Typography>
        )}
      </Box>
    </StyledAchievements>
  );
};
