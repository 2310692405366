import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";
export const StyledServices = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    display: "flex",
    flexDirection: "column",
    position: "relative",
    h1: {
      color: theme.color.background,
      boxSizing: "border-box",
      textAlign: "center",
      paddingBlock: "2rem",
    },
    ".serviceWrapper": {
      position: "relative",
      minHeight: "30rem",

      ".content": {
        display: "flex",
        flexWrap: "wrap",
        gap: "2rem",
        justifyContent: "center",
      },
    },
    ".infoWrapper": {
      overflow: "hidden",
      paddingTop: "3rem",
      paddingBottom: "5rem",
      ".content": {
        padding: "3rem 20rem",
        display: "flex",
        flexDirection: "column",
        textAlign: "center",
        alignItems: "center",
        gap: "2rem",
        h2: {
          color: theme.color.dark,
        },
        "button:hover": {
          background: theme.color.dark,
          borderColor: theme.color.dark,
        },
      },
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
      ".infoWrapper": {
        paddingTop: "0",
        ".content": {
          padding: "2rem",
        },
      },
    },
  })
);
