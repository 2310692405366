import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { useMemo, useState } from "react";
import { theme } from "./globals/theme";
import { Home } from "./pages/home/Home";
import { LanguageContext } from "./globals/LanguageContext";
import { Navbar } from "./components/navbar/Navbar";
import { ThemeProvider } from "@mui/material";
import { Services } from "./pages/services/Services";
import { Footer } from "./components/footer/Footer";
import { AboutUs } from "./pages/aboutus/AboutUs";
import { Achievements } from "./pages/achievements/Achievements";
import { AchievementPage } from "./pages/achievements/achievement/Achievement";
import { Contact } from "./pages/contact/Contact";
import { FAQ } from "./pages/faq/FAQ";
import { Info } from "./pages/info/Info";
import { useLanguage } from "./stores/useLanguage";
import { ServicePage } from "./pages/services/service/Service";
import { Redirect } from "./components/Redirect";
export const App = () => {
  const [language, setLanguage] = useState("nl_BE");
  const { dictionary, fetchLanguage } = useLanguage();

  useMemo(() => {
    fetchLanguage(language).then((res) => {});
  }, [language]);

  return (
    <>
      {dictionary && (
        <LanguageContext.Provider value={{ language, dictionary: dictionary }}>
          <ThemeProvider theme={theme}>
            <Router>
              <Navbar setLanguage={setLanguage} />
              <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/services" element={<Services />} />
                <Route path="/services/:id" element={<ServicePage />} />
                <Route path="/aboutus" element={<AboutUs />} />
                <Route path="/achievements" element={<Achievements />} />
                <Route path="/achievement/:id" element={<AchievementPage />} />
                <Route path="/contact" element={<Contact />} />
                <Route path="/faq" element={<FAQ />} />
                <Route path="/info/:page" element={<Info />} />
                <Route path="/admin/:path" element={<Redirect />} />
                <Route path="*" element={<></>} />
              </Routes>
              <Footer />
            </Router>
          </ThemeProvider>
        </LanguageContext.Provider>
      )}
    </>
  );
};
