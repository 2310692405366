import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/Types";

export const StyledHomeBackground = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    width: "100%",
    height: "100%",
    position: "absolute",
    zIndex: "-2",
    overflow: "hidden",
    top: "0",
    ".shape": {
      width: "100%",
      display: "flex",
      alignItems: "center",
      position: "relative",
      ".angle_1, .angle_2, .angle_3, .angle_4": {
        position: "absolute",
        width: "51%",
        height: "5.1rem",
        transform: "skewY(5deg) translate(0rem,2.5rem)",
        bottom: "0",
      },
      ".angle_2": {
        transform: "skewY(5deg) translate(0rem, 2.58rem)",
      },
      ".angle_1": {
        transform: "skewY(-5deg) translate(0rem, 2.58rem)",
        right: "0",
      },
      ".angle_3": {
        transform: "skewY(5deg) translate(0rem, 0rem)",
      },
      ".angle_4": {
        transform: "skewY(-5deg) translate(0rem, 0rem)",
        right: "0",
      },
    },
    ".shape_1": {
      height: "50rem",
      transform: "skewY(0deg) translate(0rem, 0rem)",
      background: "green",
      ".overlay": {
        width: "100%",
        height: "100%",
        position: "absolute",
        top: "0",
        opacity: "0.8",
        background: theme.color.dark,
        zIndex: "-1",
      },
      img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        position: "absolute",
        top: "0",
        zIndex: "-2",
      },
      ".angle_1, .angle_2": {
        background: theme.color.dark,
        zIndex: "3",
      },
      ".angle_3, .angle_4": {
        background: theme.color.main,
        zIndex: "2",
      },
    },
    ".shape_2": {
      height: "40rem",
      background: theme.color.dark,
      ".angle_1, .angle_2": {
        background: theme.color.background,
      },
    },
    ".shape_3": {
      height: "40rem",
      ".angle_1, .angle_2": {
        background: theme.color.main,
      },
    },
    ".shape_4": {
      height: "25rem",
      background: theme.color.main,
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
      ".shape_2": {
        height: "50rem",
      },
      ".shape_3": {
        height: "55rem",
      },
    },
  })
);
