import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/Types";

export const StyledQuestion = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    borderRadius: "25px",
    paddingInline: "5rem",
    border: `3px solid ${theme.color.main}`,
    background: theme.color.dark,
    ".header": {
      display: "flex",
      alignItems: "center",
      height: "5rem",
      gap: "2rem",
      cursor: "pointer",
      svg: {
        color: theme.color.background,
        width: "10%",
      },
      h2: {
        color: theme.color.background,
        width: "80%",
      },
    },
    ".body": {
      paddingBlock: "1rem",
      color: theme.color.background,
      strong: { color: theme.color.main },
    },
    "&:hover .header": {
      "h2, svg": {
        color: theme.color.main,
        transition: ".5s",
      },
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
      paddingInline: "2rem",
      ".header": {
        gap: "1rem",
        svg: {
          width: "2.5rem",
        },
      },
    },
  })
);
