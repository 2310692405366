import axios, { AxiosResponse } from "axios";
import { LanguageStore } from "../stores/useLanguage";
import { SERVERURL } from "../globals/constants";
import { Set } from "../models/Set";
import { Achievement } from "../models/Achievement";

const url = `${SERVERURL}/achievement`;

export const getAchievements = async (): Promise<Achievement[]> => {
  try {
    const response: AxiosResponse = await axios.get<Achievement[]>(
      `${url}/getall/`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};

export const getAchievement = async (
  achievementId: number
): Promise<Achievement> => {
  try {
    const response: AxiosResponse = await axios.get<Achievement>(
      `${url}/get/${achievementId}`,
      {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
        },
      }
    );
    return response.data;
  } catch (error: any) {
    if (error.response) {
      return error.response.status;
    } else {
      return Promise.reject(error.message);
    }
  }
};
