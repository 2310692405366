import { Box, useTheme } from "@mui/material";
import { StyledBackground } from "./Background.styled";

type BackgroundProps = {
  type: string;
  primaryshapeheight?: string;
  secondaryshapeheight?: string;
};

export const Background = ({
  type,
  primaryshapeheight,
  secondaryshapeheight,
}: BackgroundProps) => {
  const theme = useTheme();

  return (
    <StyledBackground
      theme={theme}
      primaryshapeheight={primaryshapeheight || "15rem"}
      secondaryshapeheight={secondaryshapeheight || "10rem"}
    >
      <Box className={`${type} ${type}primary`} />
      <Box className={`${type} ${type}secondary`} />
    </StyledBackground>
  );
};
