import { create } from "zustand";
import { Message } from "../models/Message";
import { createMessage } from "../repositories/MessageRepository";

export interface MessageStore {
  addMessage: (message: Message) => Promise<number>;
}

export const useMessage = create<MessageStore>(() => ({
  addMessage: (message) => {
    return createMessage(message);
  },
}));
