import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/Types";

export const StyledNavlink = styled(Box)(({ theme }: StyledComponentProps) => ({
  position: "relative",
  borderRadius: "10px",
  "&:hover": {
    transition: ".5s",
    boxShadow: `0px 0px 6px 0px ${theme.contrast.shadowColor}`,
  },
  ".navlink": {
    width: "fit-content",
    height: "fit-content",
    background: "none",
    color: theme.color.textColor,
    fontSize: "1rem",
    padding: ".1rem 1rem",
    display: "flex",
    gap: ".5rem",
    borderRadius: "10px",
    "&:hover": {
      background: "none",
      color: theme.color.main,
    },
  },
  ".navbutton": {
    background: "none",
    width: "fit-content",
    color: theme.color.main,
    paddingInline: "1.5rem",
    border: `2px solid ${theme.color.main}`,
    "&:hover": {
      background: theme.color.main,
      color: theme.color.background,
    },
  },
  ".selected": {
    background: theme.color.main,
    color: theme.color.background,
    borderRadius: "10px",
    "&:hover": {
      background: theme.color.main,
      color: theme.color.background,
    },
  },
  ".sublinks": {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    position: "absolute",
    width: "max-content",
    boxShadow: `0px 0px 6px 0px ${theme.contrast.shadowColor}`,
    background: theme.color.background,
    borderRadius: "10px",
    paddingBlock: "1rem",
    marginTop: ".5rem",
    gap: ".5rem",
    zIndex: "60",
    ".sublink": {
      display: "flex",
      alignItems: "center",
      paddingInline: "1rem",
      boxShadow: `none`,
      width: "100%",
      boxSizing: "border-box",
      button: {
        fontSize: ".8rem",
      },
      "&:hover": {
        color: theme.color.main,
        transition: ".5s",
      },
    },
  },
  ".mobileSubLinks": {
    display: "none",
    width: "100%",
    flexWrap: "wrap",
    justifyContent: "center",
    alignItems: "center",
    gap: "1rem",
    ".sublink": {
      display: "flex",
      alignItems: "center",
      boxShadow: `0px 0px 6px 0px ${theme.contrast.shadowColor}`,
      borderRadius: "10px",
      padding: ".5rem",
      ".navlink": {
        fontSize: ".75rem",
        width: "max-content",
      },
    },
  },
  [`@media screen and (min-width: ${theme.breakpoints.values.mobile}px) and (max-width: ${theme.breakpoints.values.tablet}px)`]:
    {
      width: "80%",
      ".navlink": {
        width: "100%",
        fontSize: "1.2rem",
        svg: {
          display: "none",
        },
      },
      ".mobileSubLinks": {
        display: "flex",
      },
    },
  [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
    width: "80%",
    ".navlink": {
      width: "100%",
      fontSize: "1rem",
      svg: {
        display: "none",
      },
    },
    ".mobileSubLinks": {
      display: "flex",
      width: "95%",
      margin: "auto",
      flexWrap: "wrap",
      ".sublink": {
        margin: "auto",
        zIndex: "2",
        ".navlink": {
          fontSize: ".75rem",
          display: "none",
          width: "100%",
        },
      },
    },
  },
}));
