import { create } from "zustand";
import { getLanguageList } from "../repositories/LanguageRepository";

export interface LanguageStore {
  fetchLanguage: (language: string) => Promise<number>;
  dictionary?: unknown;
}

export const useLanguage = create<LanguageStore>((set) => ({
  fetchLanguage: (language) => {
    return getLanguageList(set, language);
  },
}));
