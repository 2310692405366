import { LanguageButton } from "../models/Types";
import { BE, GB } from "country-flag-icons/react/3x2";
import { Achievement } from "../models/Achievement";
import { Question } from "../models/Question";

export const isInDevMode = false;

export const LanguageList: LanguageButton[] = [
  {
    code: "nl_BE",
    flag: <BE title="Flag Belgium" />,
  },
  {
    code: "en_GB",
    flag: <GB title="Flag England" />,
  },
];
export const formPlaceholders = {
  firstName: "Jan",
  lastName: "Janssens",
  email: "jan.janssens@mail.com",
  password: "*********",
};

export const data = {
  phoneNr: "+32 468/15.71.56",
  email: "info@selico.be",
  address: "Halensebaan 155A, 3390, Tielt-Winge",
  instagram: "@selicobv",
  vat: "BE 0778.887.729",
};

export const achievements: Achievement[] = [];

export const questions: Question[] = [];

export const SERVERURL = isInDevMode
  ? "http://localhost:5000/api"
  : "https://backend.selico.be/api";
