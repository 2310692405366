import { useContext } from "react";
import { LanguageContext } from "../globals/LanguageContext";
import {
  Briefcase,
  EnvelopeSimple,
  House,
  InstagramLogo,
  PhoneCall,
} from "@phosphor-icons/react";
import { data } from "../globals/constants";

export const useDataCards = (iconSize: number) => {
  const { dictionary } = useContext(LanguageContext);

  const dataCards = [
    {
      icon: <PhoneCall size={iconSize} />,
      title: dictionary.data.phonenr,
      value: data.phoneNr,
    },
    {
      icon: <EnvelopeSimple size={iconSize} />,
      title: dictionary.data.email,
      value: data.email,
    },
    {
      icon: <House size={iconSize} />,
      title: dictionary.data.address,
      value: data.address,
    },
    {
      icon: <InstagramLogo size={iconSize} />,
      title: dictionary.data.instagram,
      value: data.instagram,
    },
    {
      icon: <Briefcase size={iconSize} />,
      title: dictionary.data.vat,
      value: data.vat,
    },
  ];

  return { dataCards };
};
