import { Box, Button, Typography } from "@mui/material";
import { useContext } from "react";
import { LanguageContext } from "../../../globals/LanguageContext";
import { Service } from "../../../models/Service";
import { StyledServiceCard } from "./ServiceCard.styled";
import { useNavigate } from "react-router-dom";
import { useTextFormatter } from "../../../hooks/useTextFormatter";

type ServiceCardProps = {
  service: Service;
};

export const ServiceCard = ({ service }: ServiceCardProps) => {
  const { language, dictionary } = useContext(LanguageContext);
  const navigate = useNavigate();
  const { renderText } = useTextFormatter();

  return (
    <StyledServiceCard>
      <Box className="header">
        <Box className="imageOverlay" />
        <img src={`data:image/png;base64,${service.image}`} />
        <Typography variant="h3">
          {language === "nl_BE"
            ? service.title.translationDutch
            : service.title.translationEnglish}
        </Typography>
      </Box>
      <Box className="body">
        <Typography>
          {language === "nl_BE"
            ? renderText(service.description.translationDutch)
            : renderText(service.description.translationEnglish)}
        </Typography>
      </Box>
      <Button onClick={() => navigate(`/services/${service.id}`)}>
        {dictionary.buttons.viewmorebtn}
      </Button>
    </StyledServiceCard>
  );
};
