import { Box, Button, Typography } from "@mui/material";
import { StyledFooter } from "./Footer.styled";
import logoWhite from "../../images/Logo/selico.logo.white.svg";
import { useContext, useEffect, useState } from "react";
import { LanguageContext } from "../../globals/LanguageContext";
import { useDataCards } from "../../hooks/useDataCards";
import { useNavigate } from "react-router-dom";
import { useFooterLinks } from "../../hooks/useFooterLinks";
import { useService } from "../../stores/useServices";
import { Service } from "../../models/Service";

export const Footer = () => {
  const { language, dictionary } = useContext(LanguageContext);
  const { dataCards } = useDataCards(32);
  const { footerLinks } = useFooterLinks();
  const navigate = useNavigate();
  const { fetchServices } = useService();
  const [services, setServices] = useState<Service[] | undefined>();

  useEffect(() => {
    fetchServices().then((res) => {
      setServices(res);
    });
  }, []);

  return (
    <StyledFooter>
      <Box className="body">
        <Box className="logoWrapper">
          <img src={logoWhite} />
        </Box>
        <Box className="content">
          <Typography variant="h2">{dictionary.data.title}</Typography>
          <Box className="divider" />
          {dataCards.map((datacard, index) => (
            <Box className="info" key={index}>
              {datacard.icon}
              <Typography variant="h4">{datacard.value}</Typography>
            </Box>
          ))}
        </Box>
        <Box className="content">
          <Typography variant="h2">{dictionary.services.title}</Typography>
          <Box className="divider" />
          {services &&
            services.map(
              (service, index) =>
                service.enabled && (
                  <Box className="info" key={index}>
                    {service.icon}
                    <Button onClick={() => navigate(`/services/${service.id}`)}>
                      {language === "nl_BE"
                        ? service.title.translationDutch
                        : service.title.translationEnglish}
                    </Button>
                  </Box>
                )
            )}
        </Box>
        <Box className="content">
          <Typography variant="h2">{dictionary.footer.title}</Typography>
          <Box className="divider" />
          {footerLinks.map((footerLink, index) => (
            <Button onClick={() => navigate(footerLink.path)} key={index}>
              {footerLink.title}
            </Button>
          ))}
        </Box>
      </Box>
      <Box className="copyrightbar">
        <Typography variant="body2">
          Selico since 2024 - Copyright © All rights reserved - Website provided
          by Selico bv
        </Typography>
      </Box>
    </StyledFooter>
  );
};
