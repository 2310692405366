import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledHome = styled(Box)(({ theme }: StyledComponentProps) => ({
  position: "relative",
  ".homepage": {
    height: "50rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: "2rem",
    h1: {
      color: theme.color.background,
      background: theme.color.main,
      padding: ".5rem 3rem",
      borderRadius: "30px",
      clipPath: "polygon(50% 0, 100% 12%, 100% 88%, 48% 100%, 0 88%, 0 12%)",
    },
    h3: {
      color: theme.color.background,
    },
  },
  ".introductionWrapper": {
    height: "35rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    ".imageWrapper": {
      height: "25rem",
      width: "30rem",
      border: `4px solid ${theme.color.main}`,
      borderRadius: "10px",
      overflow: "hidden",
      img: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },
    },
  },
  ".workareaWrapper": {
    height: "40rem",
    marginTop: "2.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    ".imageWrapper": {
      boxShadow: `0px 0px 6px 0px ${theme.contrast.shadowColor}`,
      height: "25rem",
      width: "30rem",
      border: `4px solid ${theme.color.dark}`,
      borderRadius: "10px",
      background: theme.color.dark,
      position: "relative",
      overflow: "hidden",
      ".backgroundImage": {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        position: "absolute",
        opacity: "0.8",
      },
      ".map": {
        width: "60%",
        height: "40%",
        objectFit: "contain",
        zIndex: "1",
        position: "absolute",
        bottom: "-1rem",
        opacity: "1",
        right: "1rem",
      },
    },
  },
  ".ctaWrapper": {
    height: "20rem",
    marginTop: "2.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    ".content": {
      height: "70%",
      display: "flex",
      justifyContent: "space-evenly",
      flexDirection: "column",
      alignItems: "center",
      "h2, h3": {
        color: theme.color.background,
      },
      ".divider": {
        background: theme.color.dark,
        width: "10rem",
        height: ".25rem",
      },
      button: {
        background: theme.color.dark,
        textTransform: "inherit",
        "&:hover": {
          background: theme.color.lighter,
        },
      },
    },
  },
  [`@media screen and (min-width: ${theme.breakpoints.values.mobile}px) and (max-width: ${theme.breakpoints.values.tablet}px)`]:
    {
      ".introductionWrapper": {
        flexDirection: "column",
        height: "45rem",
        alignItems: "center",
        gap: "2rem",
        ".imageWrapper": {
          height: "20rem",
          width: "25rem",
        },
      },
      ".workareaWrapper": {
        height: "50rem",
        flexDirection: "column",
        marginTop: "5rem",
        ".imageWrapper": {
          height: "20rem",
          width: "25rem",
        },
      },
    },
  [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
    ".homepage": {
      h1: {
        padding: ".5rem 2rem",
      },
      h3: {
        textAlign: "center",
        fontWeight: "bold",
        fontSize: "1.5rem",
        paddingInline: "3rem",
      },
    },
    ".introductionWrapper": {
      flexDirection: "column",
      height: "45rem",
      alignItems: "center",
      gap: "2rem",
      ".imageWrapper": {
        height: "15rem",
        width: "20rem",
      },
    },
    ".workareaWrapper": {
      height: "50rem",
      flexDirection: "column",
      marginTop: "5rem",
      gap: "2rem",
      ".imageWrapper": {
        height: "15rem",
        width: "20rem",
        ".map": {
          bottom: "0rem",
        },
      },
    },
  },
}));
