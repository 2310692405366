import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/Types";

export const StyledAchievement = styled(Box)(
  ({ theme }: StyledComponentProps) => ({
    display: "flex",
    flexDirection: "column",
    alingItems: "center",
    textAlign: "center",
    paddingBlock: "2rem",
    h1: {
      color: theme.color.main,
    },
    h2: {
      color: theme.contrast.lighter,
    },
    ".backbtn": {
      display: "flex",
      gap: ".5rem",
      position: "absolute",
      right: "1rem",
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
      h1: {
        paddingTop: "5rem",
      },
    },
  })
);
