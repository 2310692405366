import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../../models/Types";

interface StyledDataCardProps extends StyledComponentProps {
  isaddress: boolean;
}

export const StyledDataCard = styled(Box)(
  ({ isaddress, theme }: StyledDataCardProps) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-Start",
    padding: "1rem 2rem",
    borderRadius: "25px",
    background: theme.color.dark,
    width: isaddress ? "35rem" : "15rem",
    gap: "1.5rem",
    ".textWrapper": {
      h3: {
        gridArea: "1 / 2 / 3 / 3",
        fontWeight: "bold",
        fontSize: "1.6rem",
        color: theme.color.background,
      },
      h4: {
        gridArea: "3 / 2 / 5 / 3",
        fontSize: "1.3rem",
        fontWeight: "bold",
        color: theme.color.lighter,
      },
    },
    svg: {
      gridArea: "1 / 1 / 3 / 2",
      color: theme.color.background,
      padding: "0",
      margin: "0",
    },
    [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
      width: "90%",
      gap: "1rem",
      boxSizing: "border-box",
      ".textWrapper": {
        h3: {
          fontSize: "1.4rem",
        },
        h4: {
          fontSize: "1rem",
        },
      },
    },
  })
);
