import { Box, Typography } from "@mui/material";
import { Achievement } from "../../../models/Achievement";
import { StyledAchievementCard } from "./AchievementCard.styled";
import { useNavigate } from "react-router-dom";

type AchievementCardProps = {
  achievement: Achievement;
};

export const AchievementCard = ({ achievement }: AchievementCardProps) => {
  const navigate = useNavigate();

  return (
    <StyledAchievementCard
      onClick={() => navigate(`/achievement/${achievement.id}`)}
    >
      <Box className="imageWrapper">
        <Box className="darkoverlay" />
        <img src={`data:image/png;base64,${achievement.images[0]}`} />
      </Box>
      <Typography variant="h2">{achievement.location}</Typography>
    </StyledAchievementCard>
  );
};
