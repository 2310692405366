import { useContext } from "react";
import { LanguageContext } from "../globals/LanguageContext";

export const useFooterLinks = () => {
  const { dictionary } = useContext(LanguageContext);

  const footerLinks = [
    {
      title: dictionary.footer.links_faq,
      path: "/faq",
    },
    {
      title: dictionary.footer.links_privacy,
      path: "/info/privacy",
    },
    {
      title: dictionary.footer.links_cookies,
      path: "/info/cookies",
    },
    {
      title: dictionary.footer.links_conditions,
      path: "/info/conditions",
    },
  ];

  return { footerLinks };
};
