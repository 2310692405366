import { Box, Fade } from "@mui/material";
import { StyledHomeBackground } from "./HomeBackground.styled";
import { useEffect, useMemo, useState } from "react";
import image_home1 from "../../../images/Home/homeimage1.jpg";
import image_home2 from "../../../images/Home/homeimage2.jpg";
import image_home3 from "../../../images/Home/homeimage3.png";

const homeImages = [image_home1, image_home2, image_home3];

export const HomeBackground = () => {
  const [homeImageIndex, setHomeImageIndex] = useState<number>(0);
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  useEffect(() => {
    setTimeout(() => {
      setHomeImageIndex(homeImageIndex + 1);
      if (homeImageIndex === homeImages.length - 1) setHomeImageIndex(0);
    }, 10 * 1000);
  }, [homeImageIndex]);

  useMemo(() => {
    setTimeout(() => {
      setIsInitialLoad(false);
    }, 10 * 1000);
  }, []);

  return (
    <StyledHomeBackground>
      <Box className="shape shape_1">
        <Box className="overlay" />
        {homeImages.map((image, index) => (
          <Fade
            in={homeImageIndex === index}
            {...{ timeout: isInitialLoad ? 0 : 2000 }}
            key={index}
          >
            <img src={image} alt={`Homeimage ${index}`} />
          </Fade>
        ))}
        <Box className="angle_1" />
        <Box className="angle_2" />
        <Box className="angle_3" />
        <Box className="angle_4" />
      </Box>

      <Box className="shape shape_2">
        <Box className="angle_1" />
        <Box className="angle_2" />
      </Box>
      <Box className="shape shape_3">
        <Box className="angle_1" />
        <Box className="angle_2" />
      </Box>
      <Box className="shape shape_4" />
    </StyledHomeBackground>
  );
};
