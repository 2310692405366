import { create } from "zustand";
import { getService, getAllServices } from "../repositories/ServiceRepository";
import { Service } from "../models/Service";

export interface ServiceStore {
  fetchServices: () => Promise<Service[] | undefined>;
  fetchService: (id: number) => Promise<Service | undefined>;
}

export const useService = create<ServiceStore>(() => ({
  fetchServices: () => {
    return getAllServices();
  },
  fetchService: (id) => {
    return getService(id);
  },
}));
