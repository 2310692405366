import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledContact = styled(Box)(({ theme }: StyledComponentProps) => ({
  ".header": {
    paddingTop: "1rem",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    h1: {
      color: theme.color.dark,
      fontSize: "52px",
    },
    ".description": {
      width: "50%",
      textAlign: "center",
      color: theme.color.dark,
    },
    ".warning": {
      color: theme.palette.warning.main,
      paddingBlock: "1rem",
      width: "50%",
      textAlign: "center",
    },
  },
  ".body": {
    display: "flex",
    justifyContent: "space-evenly",
    ".infoWrapper": {
      width: "40rem",
      display: "flex",
      flexDirection: "column",
      gap: "1rem",
      justifyContent: "flex-end",
      h2: {
        color: theme.color.background,
        paddingLeft: "1.5rem",
      },
      ".divider": {
        width: "7.5rem",
        height: ".25rem",
        background: theme.color.dark,
        marginLeft: "1.5rem",
      },
      ".content": {
        display: "flex",
        flexWrap: "wrap",
        justifyContent: "center",
        gap: "1rem",
      },
    },
  },
  ".mapWrapper": {
    height: "40rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingInline: "15rem",
    ".map": {
      overflow: "hidden",
      width: "100%",
      height: "70%",
      background: theme.color.background,
      boxShadow: `0px 0px 10px 10px ${theme.contrast.shadowColor}`,
      borderRadius: "25px",
      iframe: {
        width: "100%",
        height: "100%",
      },
    },
  },
  [`@media screen and (min-width: ${theme.breakpoints.values.mobile}px) and (max-width: ${theme.breakpoints.values.tablet}px)`]:
    {
      ".header": {
        h1: {
          textAlign: "center",
        },
        ".description, .warning": {
          width: "90%",
        },
      },
      ".body": {
        flexDirection: "column",
        gap: "1rem",
        padding: "0",
        alignItems: "center",
      },
      ".mapWrapper": {
        padding: "2rem",
        height: "30rem",
      },
    },
  [`@media screen and (max-width: ${theme.breakpoints.values.mobile}px)`]: {
    ".header": {
      h1: {
        textAlign: "center",
      },
      ".description, .warning": {
        width: "90%",
      },
    },
    ".body": {
      flexDirection: "column",
      gap: "1rem",
      padding: "0",
      alignItems: "center",
      ".infoWrapper": {
        width: "100%",
      },
    },
    ".mapWrapper": {
      padding: "1rem",
      height: "20rem",
    },
  },
}));
