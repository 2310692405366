import { Box, styled } from "@mui/material";
import { StyledComponentProps } from "../../models/Types";

export const StyledFAQ = styled(Box)(({ theme }: StyledComponentProps) => ({
  minHeight: "50rem",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  paddingBlock: "2rem",
  h1: {
    textAlign: "center",
    color: theme.color.background,
  },
  ".questionWrapper": {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    gap: "1rem",
    minHeight: "20rem",
    paddingBlock: "5rem",
    h3: {
      textAlign: "center",
      color: theme.color.lighter,
    },
  },
  button: {
    width: "50rem",
    height: "3rem",
    border: `3px solid ${theme.color.main}`,
    "&:hover": {
      border: `3px solid ${theme.color.main}`,
    },
  },
  [`@media screen and (max-width: ${theme.breakpoints.values.tablet}px)`]: {
    h1: {
      fontSize: "2rem",
    },
    ".questionWrapper": {
      width: "90%",
      paddingBlock: "2rem",
    },
    button: {
      width: "90%",
      paddingBlock: "1rem",
      height: "fit-content",
    },
  },
}));
